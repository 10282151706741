import Component from '../../../shared/component';

export default class MinorityGroupsManager extends Component {
  constructor(element) {
    super(...arguments);

    this.$element = $(element);
    this.initializeElements();
    this.bindEvents();
    this.updateState();
  }

  initializeElements() {
    this.$lgbtqQuestion = this.$element.find('#lgbtq-identification-question');
    this.$lgbtqCheckbox = this.$element.find(
      '.js-minority-group-checkbox[data-group="2SLGBTQ+"]'
    );
    this.$yesRadio = this.$element.find(
      'input[name="mentor_profile[show_lgbtq_identification]"][value="true"]'
    );
    this.$noRadio = this.$element.find(
      'input[name="mentor_profile[show_lgbtq_identification]"][value="false"]'
    );
  }

  bindEvents() {
    this.handleMinorityGroupChange = this.handleMinorityGroupChange.bind(this);

    this.$element.on(
      'change.MinorityGroupsManager',
      '.js-minority-group-checkbox',
      (event) => {
        this.handleMinorityGroupChange(event.target);
      }
    );

    $(document).on('turbolinks:load.MinorityGroupsManager', () => {
      this.initializeElements();
      this.updateState();
    });
  }

  unbindEvents() {
    this.$element.off('change.MinorityGroupsManager');
    $(document).off('turbolinks:load.MinorityGroupsManager');
  }

  updateState() {
    if (this.$lgbtqCheckbox.length) {
      const isChecked = this.$lgbtqCheckbox.is(':checked');
      this.$lgbtqQuestion.toggle(isChecked);

      if (!isChecked && this.$noRadio.length) {
        this.$noRadio.prop('checked', true);
        this.$yesRadio.prop('checked', false);
      }
    }
  }

  handleMinorityGroupChange(checkbox) {
    if (checkbox.dataset.group === '2SLGBTQ+') {
      const isChecked = $(checkbox).is(':checked');
      this.$lgbtqQuestion.toggle(isChecked);

      if (!isChecked && this.$noRadio.length) {
        this.$noRadio.prop('checked', true);
        this.$yesRadio.prop('checked', false);
      }
    }
  }

  static initialize(selector) {
    const initialize = () => {
      $(selector).each((_, element) => {
        if (!$(element).data('minority-groups-manager-initialized')) {
          const instance = new this(element);
          $(element).data('minority-groups-manager-initialized', true);
          $(element).data('minority-groups-manager-instance', instance);
        }
      });
    };

    initialize();
    $(document).on('turbolinks:load', initialize);
  }
}
